import { updateCookieMatching } from 'LiveBoard/api/cookieMatching'
import { cookieMatch as marketoCookieMatch } from './marketo'

import get from 'lodash/get'

const PROVIDERS =  {
  marketo: {
    cookieMatchMethod: marketoCookieMatch
  }
}

const CookieMatching = (config = {}) => {
  const { type, run_cookie_matching, load_local_script } = config
  const cookieMatchMethod = get(PROVIDERS[type], 'cookieMatchMethod')
  if (!cookieMatchMethod) {
    return
  }

  const apiKey = config.api_key

  const cookieMatch = () => {
    !!type && cookieMatchMethod(apiKey, cookieMatchHandler, load_local_script)
  }
  const cookieMatchHandler = (value, isSuccess) => {
    if(run_cookie_matching)
      updateCookieMatching(type, value, isSuccess)
  }
  cookieMatch()
}

export default CookieMatching
