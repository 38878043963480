const MAX_RETRIES = 4;
const INTERVAL_TIME = 1 * 1000;

export function cookieMatch(apiKey, onCookieMatch, load_local_script) {
  load_local_script ? (function() {
    var s = document.createElement('script');
    var didInit = false;
    s.type = 'text/javascript';
    s.src = '//munchkin.marketo.net/munchkin.js';
    s.onreadystatechange = function() {
        if (this.readyState == 'complete' || this.readyState == 'loaded') {
          scriptLoaded();
        }
    };
    s.onload = function(){
      scriptLoaded();
    };

    function scriptLoaded(){
      if(!didInit) {
        didInit = true;
        window.Munchkin.init(apiKey);
        initMunchkin(onCookieMatch);
      }
    }
    document.getElementsByTagName('head')[0].appendChild(s);
  })() : initMunchkin(onCookieMatch);
}

function initMunchkin(onCookieMatch) {
      var iterations = 0;
      var intervalId = setInterval(function(){
        var cookie_value = getMarketoCookie()
        var isSuccess = cookie_value && cookie_value.length > 0
        if(iterations >= MAX_RETRIES || isSuccess){
          onCookieMatch(cookie_value, isSuccess);
          clearInterval(intervalId)
        }
        iterations ++;
      }, INTERVAL_TIME)
}

function getMarketoCookie(){
  let cookiename = '_mkto_trk='
  let ca = document.cookie.split(';')
  for (let c of ca) {
      while (c.charAt(0)==' ') c = c.substring(1,c.length)
      if (c.indexOf(cookiename) == 0) return c.substring(cookiename.length,c.length)
  }
  return null
}